
import React, { useLayoutEffect, useState } from 'react';
import axios from "axios";
import 'devextreme/data/odata/store';
import "./map.css";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Filezone,{ Filezonefile } from './Filezone';

import TextField from '@material-ui/core/TextField';

import { Modal, Button } from "react-bootstrap";
import Gallery from 'devextreme-react/gallery';
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    Export,
    ColumnFixing,
    Editing} from 'devextreme-react/data-grid';
import { EmptyItem} from 'devextreme-react/form';
//import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import Geocode from "react-geocode";
import MapContainer from "./Map"

import Swal from 'sweetalert2';
//import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';

const PropertyList = () => {

    const [dataList, setdataList] = useState([]);
    const [prID, setPrID] = useState("");
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [mapModal, setMapModal] = useState(false);
    const [location, setLocation] = useState({lat:53.9171, lng:122.74, name:'My Location'})
    var lat=53.9171, lng=122.74;
    
    const modClose = () => setModalShow(false);
    const modShow = () => setModalShow(true);
    const [imgShow, setImgShow] = useState(false);     
    const imgmodClose = () => setImgShow(false);
    
    const [dropShow, setDropShow] = useState(false);     
    const dropClose = () => setDropShow(false);

    const getUploadParams = ({ file, meta }) => {
        const body = new FormData()
        body.append('file', file)
        body.append('propertyid',prID)
        return { url: 'https://larpapi.zovaya.net/api/primgupload', body }
    }


    const getUploadFiles = ({ file, meta }) => {
        const body = new FormData()
        body.append('file', file)
        body.append('propertyid',prID)
        return { url: 'https://larpapi.zovaya.net/api/prfileupload', body }
    }
    
    
    const handleSubmit = (event)=> {
    };



    const [imgGallery, setGallery] = useState([]);  
    const [galleryTitle, setGalleryTitle] = useState([]);
    
    const [expandImg, setExpandImg] = useState(""); 
    const [expandShow, setExpandShow] = useState('none'); 
    const expandClose = () => setExpandShow('none');

    const getImg = (data) => {
        fetch("https://larpapi.zovaya.net/api/property/"+data)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setGalleryTitle(res[0].propertyaddress)                
                
                if(res[0].image==null){                    
                    setGallery([])
                    console.log("fail map")
                }else{
                    const imgage =res[0].image.split(',') 
                    
                    console.log(imgage)
                    setGallery(imgage)
                }
                
            });
        setImgShow(true)      

    };
    
    const [infoDetail, setDetail] = useState([]);
    // setDetail(infoLease)

    const getInfo = (data) => {
        console.log(data)
        setDetail(data)
        setModalShow(true)        

    };

    const exportFormats = ['xlsx'];

    useLayoutEffect(() => {
        console.log('Test');
        axios.get("https://larpapi.zovaya.net/api/propertylist").then(resp => {
            if (resp.status === 200) {
                // resp.data.forEach((itm) => {
                //     itm.leasetype = leaseTypes.filter(v => v.value === itm.leasetype)[0].name
                //     //itm.commdate = itm.comdate ? intl.parseDate(itm.comdate) : null 
                //     //itm.expdate = itm.expdate ? intl.parseDate(itm.expdate) : null
                //     itm.address1 = `${itm.address1} ${itm.address2} ${itm.city} ${itm.state} ${itm.zip ? itm.zip : ''}`
                // })
                setdataList(resp.data)
                //setDataResult(resp.data, dataState);
            }
        })
    }, [])

    const isCountyOwned = (rowData) => {
        return rowData.iscountyowned === 1 ? "Yes" : "No"
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const onRowInserting = React.useCallback((e) => {
        Swal.fire(
            'Done ',
            'Property added Successfully!',
            'success'
        )
        axios.get("https://larpapi.zovaya.net/api/propertylist",
        {
            headers:{
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
            }
        }).then(resp => {
            if (resp.status === 200) {
                setdataList(resp.data);
            }
        })
    })
    const onSaving = React.useCallback((e) => {
        var change = e.changes[0];
        var type   ='';
        console.log(change)
        if ("insertBeforeKey" in change) {
            console.log(change.insertBeforeKey)
            type = 'add';
        }

        if (change.type=="remove" && type !=='add' ) {
            e.promise = axios.put("https://larpapi.zovaya.net/api/propdel", { propertyid: change.key })
                .then(resp => {
                    axios.get("https://larpapi.zovaya.net/api/propertylist",
                    {
                        headers:{
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                        }
                    }).then(resp => {
                        if (resp.status === 200) {
                            setdataList(resp.data);
                        }
                    })
                });
        }
        else if (type == 'add') {
            e.promise = axios.put("https://larpapi.zovaya.net/api/propertyadd", { propertyid: change.key, values: change.data })
                .then(resp => {
                    // no nothing medio
                    
                });
        }
        else if (change) {
            e.promise = axios.put("https://larpapi.zovaya.net/api/propertygrid", { propertyid: change.key, values: change.data })
                .then(resp => {
                    // no nothing
                });
        }
    }, []);
    const onRowClick = ((e) => {
        // console.log(e.data);
        getInfo(e.data)
    });

    const onExporting = ((e) => {
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Data');
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PropertyData.xlsx');
                });
            });
        }
        // const doc = new jsPDF();
        else if (e.format === 'pdf') {
            // exportDataGridToPdf({
            //     jsPDFDocument: doc,
            //     component: e.component
            // }).then(() => {
            //     doc.save('Companies.pdf');
            // })
        };
    });


    return(
        <div>
            <div>

<React.Fragment>
            {/* <h2 className={'content-block'}>Property List&nbsp;&nbsp;
                <a onClick={()=>{
                        document.getElementsByClassName('dx-datagrid-addrow-button')[0].click();                        
                    }
                    } title="Add New">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                        <path fill="#4caf50" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#fff" d="M21,14h6v20h-6V14z"></path><path fill="#fff" d="M14,21h20v6H14V21z"></path>
                    </svg>
                </a>
            </h2> */}

            <DataGrid
                keyExpr="propertyid"
                className={'dx-card wide-card'}
                dataSource={dataList}
                showRowLines={false}
                showBorders={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                onRowInserting={onRowInserting}
                
                onExporting={onExporting}
                onSaving={onSaving}>
                <Editing mode="popup" allowAdding="true"  allowUpdating={true} allowDeleting={true} />
                <ColumnFixing enabled={true} />
                <Paging defaultPageSize={25} />
                <Pager showPageSizeSelector={true} showInfo={true} allowedPageSizes={[5, 10, 25, 50, 100, 'all']} />
                <Export enabled={true} formats={exportFormats} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />                
                <Column  dataType={String} formItem={{visible:false}}  cellRender={(data)=>{
                    let addrs = data.data.propertyaddress;
                    if(data.data.city){
                        addrs =addrs+','+data.data.city;
                    }
                    if(data.data.state){
                        addrs =addrs+','+data.data.state;
                    }
                    return (<><a href="#" onClick={()=>{  
                        getImg(data.data.propertyid) 
                    }
                    }>
                    <svg viewBox="0 0 24 24" fill="none" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 14.2864C3.14864 15.1031 2 16.2412 2 17.5C2 19.9853 6.47715 22 12 22C17.5228 22 22 19.9853 22 17.5C22 16.2412 20.8514 15.1031 19 14.2864M18 8C18 12.0637 13.5 14 12 17C10.5 14 6 12.0637 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z" stroke="#780707" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </a>&nbsp;&nbsp;<a href="#" onClick={()=>{
                        Geocode.setApiKey("AIzaSyCoF6FyvcEj2_prKZgq4bZmBzBjhf1KwSs")
                        Geocode.setLanguage("en");
                        Geocode.setLocationType("ROOFTOP");
                        console.log("geocode")
                        Geocode.fromAddress(addrs).then(
                        (response) => {
                            console.log(response.results[0].geometry.location)
                            lat = response.results[0].geometry.location.lat;
                            lng = response.results[0].geometry.location.lng
                            setLocation({lat:lat, lng:lng, name: data.value});
                            console.log(`Lat:${lat}Lng:${lng}`)
                        })
                        setMapModal(true)
                        }
                        }><svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"  color='orange' fill='orange' viewBox="0 0 24 24">
                        <path d="M20.5 3l-0.16 0.030-5.34 2.070-6-2.1-5.64 1.9c-0.21 0.070-0.36 0.25-0.36 0.48v15.12c0 0.28 0.22 0.5 0.5 0.5l0.16-0.030 5.34-2.070 6 2.1 5.64-1.9c0.21-0.070 0.36-0.25 0.36-0.48v-15.12c0-0.28-0.22-0.5-0.5-0.5zM15 19l-6-2.11v-11.89l6 2.11v11.89z"></path>
                        </svg></a></>)
                    
                }}/>
                <Column dataField={'propertyaddress'} caption={'Location'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'city'} caption={'City'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'state'} caption={'State'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'zip'} caption={'ZIP+4'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'areasf'} caption={'Area SF'} dataType={Number} format={'#,###'} />
                <Column dataField={'siteid'} caption={'Site Id'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'mapgrid'} caption={'Map Grid'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'electiondistrict'} caption={'Election District'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'planarea'} caption={'Plan Area'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'censustract'} caption={'Census Tract'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'zonecode1'} caption={'Zone Code 1'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}} />
                <Column dataField={'sitename'} caption={'Site Name'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'councilmandistrict'} caption={'Councilman District'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'liber'} caption={'Liber'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}} />
                <Column dataField={'totalaccountinsite'} caption={'Total Account Insite'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'siteacreage'} caption={'Site Acreage'} dataType={Number} format={'#,###.##'} />
                <Column dataField={'taxaccountacreage'} caption={'Tax Account Acreage'} dataType={Number} format={'#,###.##'} />
                <Column dataField={'propertydescription'} caption={'Property Description'} />
                <Column dataField={'accountnumber'} caption={'Account Number'} cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ getInfo(data.data)} }>{data.value}</a></>)}}/>
                <Column dataField={'acquisitionprice'} caption={'Acquisition Cost'} dataType={Number} format={'currency'} />
                <Column dataField={'folio'} caption={'Folio'} dataType={Number} format={'#,###'} />
                <Column dataField={'lot'} caption={'Lot'} dataType={Number} format={'#,###'} />
                <Column dataField={'parcel'} caption={'Parcel'} dataType={Number} format={'#,###'} />
                <Column dataField={'legislativedistrict'} caption={'Legislative District'} />
                <Column dataField={'intendedusedescription'} caption={'Intended Use Description'} />
                <Column dataField={'presentstatusdescription'} caption={'Present Status Description'} />
                <Column dataField={'taxaccountno'} caption={'Tax Account No'} />
                <Column dataField={'acquisitiondate'} caption={'Acquisition Date'} dataType={'datetime'} format={'MM/dd/yyyy'} />
                <Column dataField={'acquisitionmethoddescription'} caption={'Acquisition Method Description'} />
                <Column dataField={'sitedevelopmentstatusdescription'} caption={'Site Development Status Description'} />
                <Column dataField={'ownername'} caption={'Owner Name'} />
                <Column dataField={'ownercode'} caption={'Owner Code'} />
                <Column dataField={'iscountyowned'} caption={'Is County Owned'} calculateCellValue={isCountyOwned} />
                <Column dataField={'proposedsalesprice'} caption={'Sales Price'} dataType={Number} format={'currency'} />
                <Column dataField={'currentassessvalue'} caption={'Current Assess Value'} dataType={Number} format={'currency'} />
                <Column dataField={'mapno'} caption={'Map No'} />
                <Column dataField={'mncppcid'} caption={'MNCPPC OCC-ID'} dataType={Number} />
                <Column dataField={'zoning'} caption={'Zoning'} />
                <Column dataField={'requestforquotedate'} caption={'Request for Quote Date'} dataType={'datetime'} format={'MM/dd/yyyy'} />
                <Column width={40} allowEditing={false} editCellRender={()=>{ return (<><input type="hidden" /></>)}}  fixed={true} fixedPosition="right" cellRender={(data)=>{ return (<><a style={{cursor:'pointer'}} onClick={()=>{ 
                    setDropShow(true) 
                    setPrID(data.data.propertyid) } } className="" title="Upload" aria-label="Upload" >
                    <svg width="24" height="24" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="4.8"> <path opacity="0.5" d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#26975A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 16V3M12 3L16 7.375M12 3L8 7.375" stroke="#26975A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g><g id="SVGRepo_iconCarrier"> <path opacity="0.5" d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#26975A" stroke-width="1.752" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 16V3M12 3L16 7.375M12 3L8 7.375" stroke="#26975A" stroke-width="1.752" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </a></>)}} />
            </DataGrid>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add Property</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmptyItem 
                                colSpan={2}
                            />
                    <form onSubmit={handleSubmit}>
                        <FormControl style={{ margin:'20px' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Location" variant="filled"  fullWidth focused  id="mapno" name="mapno" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Site Id" variant="filled" fullWidth focused  id="mncppcoccid" name="mncppcoccid"  />
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ textAlign:'right'}}>
                                        <Button  variant="light" onClick={handleClose} size="sm" style={{ marginRight:'20px'}}>
                                            Close
                                        </Button>
                                        <Button type="submit" variant="dark"  size="sm">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal
            size="lg"
            show={imgShow} onHide={imgmodClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Location : {galleryTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{height:"520px", width:"100%"}}>
                        <Gallery
                            id="gallery"
                            dataSource={imgGallery}
                            height={540}
                            slideshowDelay={0}
                            showNavButtons={true}
                            showIndicator={true}
                            onItemClick={(e) => {
                                setExpandImg(e.itemData)
                                setExpandShow('block')
                                }} />
                        </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={imgmodClose}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal size="lg"  show={dropShow} onHide={dropClose} aria-labelledby="contained-modal-vcenter" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-vcenter">Upload Imgages /Files  {galleryTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='loderdiv'>
                    <div className='file-header-label'>Add Property Image (Map Snapshots):</div>
                    <div className='file-image-zone' style={{minHeight:"300px", width:"80%" ,margin:'auto'}}>
                        <Dropzone
                            getUploadParams={getUploadParams}
                            accept="image/*"
                            inputContent={Filezone}
                            />
                    </div>
                    <div className='file-header-label'>Add Property Files (Documents) :</div>
                    <div className='file-doc-zone' style={{minHeight:"200px", width:"80%" ,margin:'auto'}}>
                        <Dropzone
                            getUploadParams={getUploadFiles}
                            accept="*"
                            inputContent={Filezonefile}
                            
                            />
                    </div>
                </div>
                </Modal.Body>
            </Modal>


            <Modal
            size="xl"
            show={modalShow} onHide={modClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Property Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{width:'100%'}}>
                <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Location Details</div>
                                        <div className='info-details'>
                                            <Grid container spacing={2}>                                               

                                                                    
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Address</div>
                                                        <div className='info-record-value'>{infoDetail.propertyaddress}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>City</div>
                                                        <div className='info-record-value'>{infoDetail.city}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>State</div>
                                                        <div className='info-record-value'>{infoDetail.state}</div>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <div className='info-record-label'>Zip+4</div>
                                                        <div className='info-record-value'>{infoDetail.zip}</div>
                                                    </Grid>
                                            
                                                
                                            </Grid>
                                            
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Site Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                              
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Site ID</div>
                                                    <div className='info-record-value'>{infoDetail.siteid}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Area SF</div>
                                                    <div className='info-record-value'>{infoDetail.areasf}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Map Grid</div>
                                                    <div className='info-record-value'>{infoDetail.mapgrid}</div>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Election District</div>
                                                    <div className='info-record-value'>{infoDetail.electiondistrict}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Plan Area</div>
                                                    <div className='info-record-value'>{infoDetail.planarea}</div>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Census Tract</div>
                                                    <div className='info-record-value'>{infoDetail.censustract}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Zone Code 1</div>
                                                    <div className='info-record-value'>{infoDetail.zonecode1}</div>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Site Name</div>
                                                    <div className='info-record-value'>{infoDetail.sitename}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Councilman District</div>
                                                    <div className='info-record-value'>{infoDetail.councilmandistrict}</div>
                                                </Grid>
                                            </Grid>
                                            
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Other Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                            

                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Liber</div>
                                                    <div className='info-record-value'>{infoDetail.liber}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Total Account Insite</div>
                                                    <div className='info-record-value'>{infoDetail.totalaccountinsite}</div>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Site Acreage</div>
                                                    <div className='info-record-value'>{infoDetail.siteacreage}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Tax Account Acreage</div>
                                                    <div className='info-record-value'>{infoDetail.taxaccountacreage}</div>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Property Description</div>
                                                    <div className='info-record-value'>{infoDetail.propertydescription}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Account Number</div>
                                                    <div className='info-record-value'>{infoDetail.accountnumber}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Acquisition Cost</div>
                                                    <div className='info-record-value'>{infoDetail.acquisitionprice}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Folio</div>
                                                    <div className='info-record-value'>{infoDetail.folio}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Lot</div>
                                                    <div className='info-record-value'>{infoDetail.lot}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Parcel</div>
                                                    <div className='info-record-value'>{infoDetail.parcel}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Legislative District</div>
                                                    <div className='info-record-value'>{infoDetail.legislativedistrict}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Intended Use Description</div>
                                                    <div className='info-record-value'>{infoDetail.intendedusedescription}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Present Status Description</div>
                                                    <div className='info-record-value'>{infoDetail.presentstatusdescription}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Tax Account No</div>
                                                    <div className='info-record-value'>{infoDetail.taxaccountno}</div>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <div className='info-record-label'>Acquisition Date</div>
                                                    <div className='info-record-value'>{infoDetail.acquisitiondate}</div>
                                                </Grid>
                                            </Grid>

                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Description</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                        
                                                <Grid item xs={12} md={6}>
                                                    <div className='info-record-label'>Acquisition Method Description</div>
                                                    <div className='info-record-value'>{infoDetail.acquisitionmethoddescription}</div>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <div className='info-record-label'>Site Development Status Description</div>
                                                    <div className='info-record-value'>{infoDetail.sitedevelopmentstatusdescription}</div>
                                                </Grid>
                                            
                                        </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Owner Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                        
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Owner Name</div>
                                                <div className='info-record-value'>{infoDetail.ownername}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Owner Code</div>
                                                <div className='info-record-value'>{infoDetail.ownercode}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Is County Owned</div>
                                                <div className='info-record-value'>{infoDetail.iscountyowned}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Sales Price</div>
                                                <div className='info-record-value'>{infoDetail.proposedsalesprice}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Current Assess Value</div>
                                                <div className='info-record-value'>{infoDetail.currentassessvalue}</div>
                                            </Grid>
                                            
                                        </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Map Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                        
                                            
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Map No</div>
                                                <div className='info-record-value'>{infoDetail.mapno}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>MNCPPC OCC-ID</div>
                                                <div className='info-record-value'>{infoDetail.mncppcid}</div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Zoning</div>
                                                <div className='info-record-value'>{infoDetail.zoning}</div>
                                            </Grid>
                                            
                                        </Grid>
                                        </div>
                                    </div>
                                </Grid>
                                
                                <Grid item xs={12} md={12}>
                                    <div>
                                        <div className='info-title'>Quote Information</div>
                                        <div className='info-details'>
                                        <Grid container spacing={2}> 
                                                
                                            <Grid item xs={12} md={3}>
                                                <div className='info-record-label'>Request for Quote Date</div>
                                                <div className='info-record-value'>{infoDetail.requestforquotedate}</div>
                                            </Grid>
                                        </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            
                            {/* {(new Date().getFullYear())} */}
                           

                            </Grid>
                </Box>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modClose}>Close</Button>
            </Modal.Footer>
            </Modal>

            <Modal id="mapModel" show={mapModal} size="lg">
            <Modal.Header>
                <Modal.Title >
                Map View
                </Modal.Title>
            <Button
                variant=""
                className="close"
                onClick={() => setMapModal(false)}
                ><span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body role='Document'>
            <div style={{height:"400px", width:"100%"}}>
            <MapContainer lat={location.lat} lng={location.lng} name={location.name}/>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <Button style={{marginTop:"20px"}} onClick={()=> setMapModal(!mapModal)}>Close</Button>
            </Modal.Footer>
            </Modal>

            <div className="expandView" style={{display: expandShow}}>
                <a className="expandCl" onClick={expandClose}>&times;</a>
                <div className="expandImg">
                    <img  src={expandImg} alt="Exapnd Image" />
                </div>

            </div>

        </React.Fragment>                
                </div>
                </div>
    )

}

export default PropertyList;