import React from "react";

import seal from '../../images/custom/seal.png'
import larplogo from '../../images/custom/larplogo.jpeg'
import backimage from '../../images/custom/backimage.png'

import './cspage.css'
import { Link } from 'react-router-dom'
//import { Link } from "react-router-dom";
const ForgotPassword = ({ history }) => {
   const onSubmit = (e) => {
      e.preventDefault();
	  console.log();
    //   history.push("/");
   };
   return (

      
		<>
		<div>
		<div style={{background:'#fff',display:'flex',justifyContent:'space-between',padding:'30px 100px'}}>
				<img className="larplogo" src={larplogo} alt='larplogo' />
				
				<img className="seallogo" src={seal} alt='larplogo' />
			</div>
		</div>
		<div  className='backimage' style={{backgroundImage:`url(${backimage})`}}>
			
			<div className='map-bdy'>
				<div style={{width:800}}>
					
				<h3 className="text-center mb-4 sign-head ">Forgot your password?</h3>
				<div id="sign-in" className="auth-form   form-validation ">
					
									
									<form onSubmit={(e) => onSubmit(e)}>
										<div className="form-group mb-5">
											<label className="mb-2 auth-labels"  htmlFor="val-email">Email</label>
											<div>
												<input type="email" className="form-control"
                                    id="email" name="email"
												   placeholder="Type Your Email Address"
												/>
											</div>
										</div>
										<div className="text-center form-group mb-3">
											
											<button type="submit" className=" btn-sign  ">
												Submit
											</button>
											
										</div>
									</form>
									<div className="sign-account mt-3">
										<p>Go back to  <Link className="text-sign" to="./login">Log in</Link></p>
									</div>
								</div>
				</div>
			</div>

		</div>

		</>
   );
};

export default ForgotPassword;
