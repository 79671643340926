import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { loadData } from "../../../store/actions/dashactions";
import axios from "axios";

import moment from 'moment';

import { DateBox } from "devextreme-react";



function Home(props) {
  const currentdate = new Date();

  const calendarOptions={
    maxZoomLevel: 'year', 
    minZoomLevel: 'century', 
}

  let month = currentdate.getMonth();
  let year = currentdate.getFullYear();


  let stDate = `${year}-01`;
  let edDate = `${year}-${month+1}`;
  console.log(month)

  useEffect(()=>{
    DashboardDetails('In-Lease',stDate,edDate)
  },[])

  const [sumdata, setSumdata] = useState([]);

    const [reportname, setReport] = useState('')
    const [ltype, setltype] = useState('')

    const convert_month = (date)=> {
        if(date != null){            
            return moment(date).format('MMM YYYY')
        }else{
            return '';
        }
    }
    const month_year = (date)=> {
      if(date != null){            
          return moment(date).format('YYYY-M')
      }else{
          return '';
      }
  }

const handleSubmit = (event)=> {
  event.preventDefault();
  console.log(event.target.startdate.value);
  const leasetype = event.target.leasetype.value;
  const startdate = month_year(event.target.startdate.value);
  const enddate = month_year(event.target.enddate.value);
  DashboardDetails(leasetype,startdate,enddate)
};
const DashboardDetails = (leasetype,startdate,enddate)=> {
      axios.post("https://larpapi.zovaya.net/api/leasegeneralreport", {
      leasetype:leasetype,
      startdate:startdate,
      enddate:enddate,
  })
  .then((response) => {
          setSumdata(response.data)
          setltype('('+leasetype+')')
          console.log(convert_month(startdate))
          if(startdate==enddate){
              setReport(convert_month(startdate))
          }else{
              setReport(convert_month(startdate)+'-'+convert_month(enddate))
          }
              
      })               
      .catch((error) => {
          console.log(error);
          
      });
};
  return (
    <>
    <div className="row mb-4 ml-1">
      <div className="col-md-8">
        <div className="report-card" >
          <div className="report-label" >Total Leases</div>
          <div className="report-value box" >{(sumdata.totalleases ||0)}</div>
        </div>
        <div className="report-card" >
          <div className="report-label" >Total Active Leases</div>
          <div className="report-value box" >{(sumdata.totalactiveleases ||0)}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Total Revenue (Annual Lease Cost )</div>
          <div className="report-value">${(sumdata.annualleasecost ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Monthly Lease Cost</div>
          <div className="report-value">${(sumdata.monthlyleasecost ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Sqft Occupied</div>
          <div className="report-value">{(sumdata.squarefeetoccupied ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label ">No.of Subleases</div>
          <div className="report-value box">{(sumdata.totalsubleases ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">No.of new Leases</div>
          <div className="report-value box">{(sumdata.noofnewleases ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Terminated Leases</div>
          <div className="report-value box">{(sumdata.noofterminatedleases ||0).toLocaleString()}</div>
        </div>
        
        <div className="report-card">
          <div className="report-label">Exp. Leases W/in 6 mos.</div>
          <div className="report-value box"  style={{background:'#C42929'}}>{(sumdata.expleasesw6mos ||0).toLocaleString()}</div>
        </div>
      </div>
      <div className="col-md-4">
      <form onSubmit={handleSubmit}  className="dash-form">
              <div className="form-row" style={{alignItems:'flex-end'}}>
                  <div className="form-group col-md-12">
                    <select className="form-control" defaultValue={"In-Lease"} id="leasetype"   name="leasetype">
                    <option value={"In-Lease"} >In-Lease</option>
                                              <option value={"Out-Lease"} >Out-Lease</option>
                                              <option value={"Licence"}>Licence</option>
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <label style={{color:'#fff'}} for="startdate">Start month</label>
                    <DateBox
                      defaultValue={currentdate}
                      // inputAttr={dateLabel}
                      type="date"
                      id="startdate" name="startdate"

                      displayFormat= "monthAndYear"
                      calendarOptions={calendarOptions}
                      
                    />
                    {/* <input type="month" className="form-control" id="startdate" name="startdate" placeholder="Start month" onfocus="(this.type='month')" onblur="(this.type='text')"  required/> */}
                  </div>
                  
                  <div className="form-group col-md-12">
                    <label style={{color:'#fff'}} for="enddate">End month</label>
                    <DateBox
                      defaultValue={currentdate}
                      // inputAttr={dateLabel}
                      type="date"
                      id="enddate" name="enddate"

                      displayFormat= "monthAndYear"
                      calendarOptions={calendarOptions}
                      
                    />
                    {/* <input type="month" className="form-control" id="enddate" name="enddate" placeholder="End month" onfocus="(this.type='month')" onblur="(this.type='text')"  required/> */}
                  </div>
                  <div className="form-group col-md-12 mt-3">
                    <button type="submit"   className="btn btn-primary btn-apl">Apply</button>
                  </div>
                </div>
              </form>

      </div>

    </div>

      
    </>
  );
}

const mapStateToProps = (state) =>
{ 
  return {
  lease: state.HomeReducer.lease,
  surplus: state.HomeReducer.surplus
  }
};

const mapDispatchToProps  = {
  loadData: (leasetype) => loadData(leasetype)
}

export default connect(mapStateToProps, mapDispatchToProps) (Home);
