import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';


import TextField from '@material-ui/core/TextField';
import axios from "axios";
import 'devextreme/data/odata/store';
import Swal from 'sweetalert2';

import "./map.css";
import { jsPDF } from 'jspdf';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import moment from 'moment';
import PageTitle from "../../../layouts/PageTitle";
import { Button } from 'react-bootstrap';
var surplusid = 0

const generateColor = () => {
    let randomColorString = "#";
    const arrayOfColorFunctions = "0123456789abcdef";
    for (let x = 0; x < 6; x++) {
      let index = Math.floor(Math.random() * 16);
    let value = arrayOfColorFunctions[index];
    randomColorString += value;
    }
    return randomColorString;
};

const SurplusReport = (props) => {
    const tableRef = useRef(null);

    const convert_date = (date)=> {
        if(date != null && date != "0000-00-00"){            
            return moment(date).format('MM/DD/YYYY')
        }else{
            return '';
        }
    }
    
    const [sumdata, setSumdata] = useState([]);

    const [reportname, setReport] = useState('')
    const convert_month = (date)=> {
        if(date != null){            
            return moment(date).format('MMM YYYY')
        }else{
            return '';
        }
    }
    const {onDownload}  = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Surplus Summary '+reportname,
        sheet: 'Surplus Summary '+reportname
    })
    const generatePDF = () => {

        const report = new jsPDF('landscape','px','a4');
        report.html(document.querySelector('#table-report')).then(() => {
            report.save('Surplus Summary '+reportname+'.pdf');
        });
    }


    

    const handleSubmit = (event)=> {
        event.preventDefault();
        const startdate = event.target.startdate.value;
        const enddate = event.target.enddate.value;
            axios.post("https://larpapi.zovaya.net/api/rangewisesummarysurplus", {
            startdate:startdate,
            enddate:enddate,
        })
        .then((response) => {
                console.log(response.data)
                setSumdata(response.data)
                console.log(convert_month(startdate))
                if(startdate==enddate){
                    setReport(convert_month(startdate))
                }else{
                    setReport(convert_month(startdate)+'-'+convert_month(enddate))
                }
                
                    
            })               
            .catch((error) => {
                console.log(error);
                
            });
    };

    
    let inputRef;

    return(
        <div>
            <div>
            <PageTitle
                activeMenu="Surplus"
                motherMenu="Surplus"
                pageContent="Surplus Details"/>
        <React.Fragment>
            <h2 className={'content-block'}>Surplus Report  </h2>
            <form onSubmit={handleSubmit}>
                        <FormControl style={{ margin:'20px',width:'100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} style={{margin:'auto',justifyContent:'center'}}>
                                    <Grid item xs={12} md={3}>
                                        <TextField label="Start Month" type="month" focused   id="startdate" name="startdate"  required/>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField label="End Month" type="month"  focused  id="enddate" name="enddate" required />
                                    </Grid>
                                    <Grid item xs={12} md={3} style={{display:'flex',alignItems:'center'}}>
                                        <Button type="submit" variant="success"  size="sm">
                                            Apply
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
            </form>

            
            <div style={{width:'80%',backgroundColor:'white',borderRadius:'5px',margin:'30px auto 0',padding:'30px'}}>
                <Box sx={{ flexGrow: 1 }}>
                    <h3 className='text-center'>Surplus Management</h3>
                    <div style={{float:'right',marginBottom:'10px'}}>
                        <a className='export-buttons' onClick={onDownload} title='Export excel' > <i className='dx-icon dx-icon-xlsxfile dx-list-item-icon'></i></a>
                        <a className='export-buttons' onClick={generatePDF} title='Export Pdf' > <i className='dx-icon dx-icon-pdffile dx-list-item-icon'></i></a>
                    </div>
                    
                    <div className="table-responsive">
                        <table className="table table-bordered" ref={tableRef} id='table-report'>
                            <thead>
                                <tr>
                                    <th className='text-center' colSpan={2}><h4>Surplus Summary {reportname}</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className='text-left'>Total Number of Properties</th>
                                    <td> {sumdata.totalnoofproperties || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'>Number of Properties Sold</th>
                                    <td> {sumdata.noofpropertiessold || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'>Number of Pending Contracts</th>
                                    <td> {sumdata.noofpendingcontracts || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'>Number of Remaining Properties to be sold </th>
                                    <td> {sumdata.noofremainingpropertiestobesold || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'>Number of Properties on Hold </th>
                                    <td> {sumdata.noofpropertiesonHold || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'>Revenue to Date</th>
                                    <td> ${sumdata.revenuetodate || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'>Revenue Expected from Pending Contracts</th>
                                    <td> ${sumdata.revenueexpectedfrompendingcontracts || 0}</td>
                                </tr>
                                <tr>
                                    <th className='text-left'> % Sold</th>
                                    <td> {sumdata.percentsold || 0} %</td>
                                </tr>
                                <tr>
                                    <th className='text-left'> % Pending</th>
                                    <td> {sumdata.percentpending || 0} %</td>
                                </tr>
                            </tbody>

                        </table>

                    </div>
                </Box>
            </div>

            



        </React.Fragment>
            </div>
        </div>
    )
}

export default SurplusReport;