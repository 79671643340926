import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import seal from '../../images/custom/seal.png'
import larplogo from '../../images/custom/larplogo.jpeg'
import backimage from '../../images/custom/backimage.png'

import './cspage.css'

import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';


function Login (props) {
  const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return ;
		}
		dispatch(loadingToggleAction(true));	
        dispatch(loginAction(email, password, props.history));
    }

  return (
		<>
		<div>
		<div style={{background:'#fff',display:'flex',justifyContent:'space-between',padding:'30px 100px'}}>
				<img className="larplogo" src={larplogo} alt='larplogo' />
				
				<img className="seallogo" src={seal} alt='larplogo' />
			</div>
		</div>
		<div  className='backimage' style={{backgroundImage:`url(${backimage})`}}>
			
			<div className='map-bdy'>
				<div style={{width:800}}>
					
				<h3 className="text-center mb-4 sign-head ">Welcome Back</h3>
				<div id="sign-in" className="auth-form   form-validation ">
					
									{props.errorMessage && (
										<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
											{props.errorMessage}
										</div>
									)}
									{props.successMessage && (
										<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
											{props.successMessage}
										</div>
									)}
									<form onSubmit={onLogin}  className="form-validate">
										<div className="form-group mb-5">
											<label className="mb-2 auth-labels"  htmlFor="val-email">Email</label>
											<div>
												<input type="email" className="form-control"
													
												   onChange={(e) => setEmail(e.target.value)}
												   placeholder="Type Your Email Address"
												/>
											</div>
											{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
										</div>
										<div className="form-group mb-3">
											<label className="mb-2 auth-labels">Password</label>
											<input
											  type="password"
											  className="form-control"
											  value={password}
											  placeholder="Type Your Password"
												onChange={(e) =>
													setPassword(e.target.value)
												}
											/>
											{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
											
											<p className='mt-2'><Link className=" forgot-labels " to="./forgot-password">Forgot Password?</Link></p>
										</div>
										<div className="text-center form-group mb-3">
											
											<button type="submit" className=" btn-sign  ">
												Sign In
											</button>
											
										</div>
									</form>
									<div className="sign-account mt-3">
										<p>Don't have an account? <Link className="text-sign" to="./register">Sign up</Link></p>
									</div>
								</div>
				</div>
			</div>

		</div>

		</>
		
  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
