import React, { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import TextField from '@material-ui/core/TextField';
import axios from "axios";
import 'devextreme/data/odata/store';
import Swal from 'sweetalert2';
import "./map.css";
import { jsPDF } from 'jspdf';
import moment from 'moment';
// import PageTitle from "../../../layouts/PageTitle";
import { Button } from 'react-bootstrap';
import { DateBox } from "devextreme-react";


const LeaseReport = (props) => {
    const tableRef = useRef(null);
    
    const [sumdata, setSumdata] = useState([]);
    const currentdate = new Date();
    const calendarOptions={
        maxZoomLevel: 'year', 
        minZoomLevel: 'century', 
    }

    const [reportname, setReport] = useState('')
    const [ltype, setltype] = useState('')

    const convert_month = (date)=> {
        if(date != null){            
            return moment(date).format('MMM YYYY')
        }else{
            return '';
        }
    }
    const month_year = (date)=> {
        if(date != null){            
            return moment(date).format('YYYY-M')
        }else{
            return '';
        }
    }
    
    const {onDownload}  = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Lease Summary '+ltype+' '+reportname,
        sheet: 'Lease Summary '+ltype+' '+reportname
    })
    const generatePDF = () => {

        const report = new jsPDF('landscape','px','a4');
        report.html(document.querySelector('#table-report')).then(() => {
            report.save('Lease Summary '+ltype+' '+reportname+'.pdf');
        });
    }
    const handleSubmit = (event)=> {
        event.preventDefault();
        const leasetype = event.target.leasetype.value;
        const startdate = month_year(event.target.startdate.value);
        const enddate = month_year(event.target.enddate.value);
            axios.post("https://larpapi.zovaya.net/api/leasegeneralreport", {
            leasetype:leasetype,
            startdate:startdate,
            enddate:enddate,
        })
        .then((response) => {
                setSumdata(response.data)
                setltype('('+leasetype+')')
                console.log(convert_month(startdate))
                if(startdate==enddate){
                    setReport(convert_month(startdate))
                }else{
                    setReport(convert_month(startdate)+'-'+convert_month(enddate))
                }
                    
            })               
            .catch((error) => {
                console.log(error);
                
            });
    };

    useEffect(()=>{
        // props.loadData('In-Lease')
    },[])

    

    

    return(
        <div>
            <div>
            <div className='col-md-12 ml-auto mr-auto text-center'>
                <h3 className='text-center rep-title'>Lease Management</h3>


                <form onSubmit={handleSubmit}  className="repo-from" style={{margin:'12px 60px',padding:13}} >
              <div className="form-row" style={{alignItems:'flex-end',justifyContent:'space-evenly'}}>
                  <div className="form-group col-md-2">
                    <select className="form-control" defaultValue={"In-Lease"} id="leasetype"   name="leasetype">
                    <option value={"In-Lease"} >In-Lease</option>
                                              <option value={"Out-Lease"} >Out-Lease</option>
                                              <option value={"Licence"}>Licence</option>
                    </select>
                  </div>
                  <div className="form-group col-md-2">
                    <label style={{color:'#000'}} for="startdate">Start month</label>
                    <DateBox
                      defaultValue={currentdate}
                      // inputAttr={dateLabel}
                      type="date"
                      id="startdate" name="startdate"

                      displayFormat= "monthAndYear"
                      calendarOptions={calendarOptions}
                      style={{border:'none'}}
                      
                    />
                    {/* <input type="month" className="form-control" id="startdate" name="startdate" placeholder="Start month" onfocus="(this.type='month')" onblur="(this.type='text')"  required/> */}
                  </div>
                  
                  <div className="form-group col-md-2">
                    <label style={{color:'#000'}} for="enddate">End month</label>
                    <DateBox
                      defaultValue={currentdate}
                      // inputAttr={dateLabel}
                      type="date"
                      id="enddate" name="enddate"

                      displayFormat= "monthAndYear"
                      calendarOptions={calendarOptions}
                      
                    />
                    {/* <input type="month" className="form-control" id="enddate" name="enddate" placeholder="End month" onfocus="(this.type='month')" onblur="(this.type='text')"  required/> */}
                  </div>
                  <div className="form-group col-md-2 mt-3">
                    <button type="submit"   className=" btn-apl btn-apply">Apply</button>
                  </div>
                  
                  <div className="form-group col-md-2 ">
                    <a className='btn btn-export' onClick={onDownload} title='Export Excel' >Export <i className='dx-icon dx-icon-xlsxfile dx-list-item-icon'></i></a>
                  </div>

                </div>
              </form>
                    
                       <h4 style={{color:'#C42929'}}>Lease Summary {ltype} {reportname}</h4>
                

                       
                
                </div>

        <React.Fragment>
       
            
            <div className="col-md-10 ml-auto mr-auto">
            <div className='mb-5 mt-5' style={{width: '100%', height: 0, border: '1px black solid'}}></div>
                
        <div className="report-card" >
          <div className="report-label" >Total Leases</div>
          <div className="report-value "  style={{color:'#2C3175'}}>{(sumdata.totalleases ||0)}</div>
        </div>
        <div className="report-card" >
          <div className="report-label" >Total Active Leases</div>
          <div className="report-value "  style={{color:'#2C3175'}}>{(sumdata.totalactiveleases ||0)}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Total Revenue (Annual Lease Cost )</div>
          <div className="report-value" style={{color:'#2C3175'}}>${(sumdata.annualleasecost ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Monthly Lease Cost</div>
          <div className="report-value">${(sumdata.monthlyleasecost ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Sqft Occupied</div>
          <div className="report-value">{(sumdata.squarefeetoccupied ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label ">No.of Subleases</div>
          <div className="report-value">{(sumdata.totalsubleases ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">No.of new Leases</div>
          <div className="report-value ">{(sumdata.noofnewleases ||0).toLocaleString()}</div>
        </div>
        <div className="report-card">
          <div className="report-label">Terminated Leases</div>
          <div className="report-value ">{(sumdata.noofterminatedleases ||0).toLocaleString()}</div>
        </div>
        
        <div className="report-card">
          <div className="report-label">Exp. Leases W/in 6 mos.</div>
          <div className="report-value "  style={{color:'#C42929'}}>{(sumdata.expleasesw6mos ||0).toLocaleString()}</div>
        </div>
      </div>
            <div style={{width:'80%',backgroundColor:'white',borderRadius:'5px',margin:'30px auto 0',padding:'30px',opacity:0}}>
                <Box sx={{ flexGrow: 1 }}>
                    <h3 className='text-center'>Lease Management</h3>
                    <div style={{float:'right',marginBottom:'10px'}}>
                        {/* <a className='export-buttons' onClick={onDownload} title='Export Excel' > <i className='dx-icon dx-icon-xlsxfile dx-list-item-icon'></i></a> */}
                        {/* <a className='export-buttons' onClick={generatePDF} title='Export Pdf' > <i className='dx-icon dx-icon-pdffile dx-list-item-icon'></i></a> */}
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered" ref={tableRef} id='table-report'>
                            <thead>
                                
                                <tr>
                                    <th className='text-center' colSpan={2}><h4>Lease Summary {ltype} {reportname}</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Annual Lease Cost</th>
                                    <td> $ {(sumdata.annualleasecost || 0).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <th>Monthly Lease Cost</th>
                                    <td> $ {(sumdata.monthlyleasecost || 0).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <th>Square Feet Occupied</th>
                                    <td> {(sumdata.squarefeetoccupied || 0).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <th>Total Leases</th>
                                    <td> {sumdata.totalleases || 0}</td>
                                </tr>
                                <tr>
                                    <th>Total Subleases</th>
                                    <td> {sumdata.totalsubleases || 0}</td>
                                </tr>
                                <tr>
                                    <th>No.of New Leases </th>
                                    <td> {sumdata.noofnewleases || 0}</td>
                                </tr>
                                <tr>
                                    <th>No.of Terminated Leases</th>
                                    <td> {sumdata.noofterminatedleases || 0}</td>
                                </tr>
                                <tr>
                                    <th>Exp. Leases W/in 6 mos</th>
                                    <td> {sumdata.expleasesw6mos || 0}</td>
                                </tr> 
                            </tbody>

                        </table>

                    </div>
                </Box>
            </div>
            
            

            


        </React.Fragment>
            </div>
        </div>
    )
}

export default LeaseReport;