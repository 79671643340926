/// Menu

import React, { useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";



import larplogo from '../../../images/custom/larplogo.jpeg'



const  SideBar = () => {

  let Pathloc = window.location.pathname
  Pathloc = Pathloc.split('/')
  Pathloc = Pathloc[Pathloc.length - 1]

  const [expanded, setExpanded] = useState(true)
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)
  const [expanded3, setExpanded3] = useState(true)

  

  // useLayoutEffect(() => {
  //   if(Pathloc==='lease-details' || Pathloc==='lease-report'){
  //     setExpanded1(!expanded1)
  //     alert(expanded1)
  //   }
  // }, []);
    

    return (
      <div className="deznav">
        <Link to="/" className="brand-logo">
            <img className="sidelogo" src={larplogo} alt="" />
         </Link>
        <PerfectScrollbar className="deznav-scroll">
          <ul className="my-menu">
            <li className={"main-item "+(expanded ? " expand ":"") + (Pathloc==='dashboard' || Pathloc==='' ? " active"  :"")} >
              <a className="menu-link" onClick={() => setExpanded(!expanded)}>
              <i class="flaticon-381-home-2"></i>
                Dashboard <span class="fa fa-chevron-down"></span></a>
                <ul className="sub-menu-item">
                  <li className="sub-item"><Link to="/" className={"sub-link "+ (Pathloc==='dashboard' || Pathloc===''  ? " active" :"")}>Lease Dashboard</Link></li>
                </ul>
            </li>
            <li className={"main-item "+(expanded1 ? " expand ":"")+ (Pathloc==='lease-details' || Pathloc==='lease-report'   ? "   active" :"")} >
              <a className="menu-link" onClick={() => setExpanded1(!expanded1)}>
              <i class="flaticon-381-networking"></i>
                Leases <span class="fa fa-chevron-down"></span></a>
              <ul className="sub-menu-item">
                  <li className="sub-item"><Link to="/lease-details" className={"sub-link "+ (Pathloc==='lease-details' ? " active" :"")}>Lease Details</Link></li>
                  <li className="sub-item"><Link to="/lease-report" className={"sub-link "+ (Pathloc==='lease-report' ? " active" :"")}>Lease Report</Link></li>
                </ul>
            </li>
            <li className={"main-item "+(expanded2 ? " expand ":"")+ (Pathloc==='county-owned-list' || Pathloc==='county-vacant-list' || Pathloc==='police-list' || Pathloc==='vfd-list' || Pathloc==='boe-list' || Pathloc==='library-list' || Pathloc==='colleges-list' || Pathloc==='appraisel-summary'   ? " active" :"")} >
              <a className="menu-link" onClick={() => setExpanded2(!expanded2)}><i class="flaticon-381-blueprint"></i> Appraisal <span class="fa fa-chevron-down"></span></a>
              <ul className="sub-menu-item">
                  <li className="sub-item"><Link to="/county-owned-list" className={"sub-link "+ (Pathloc==='county-owned-list' ? " active" :"")}>County Owned</Link></li>
                  <li className="sub-item"><Link to="/county-vacant-list" className={"sub-link "+ (Pathloc==='county-vacant-list' ? " active" :"")}>County Vacant</Link></li>
                  <li className="sub-item"><Link to="/police-list" className={"sub-link "+ (Pathloc==='police-list' ? " active" :"")}>Police</Link></li>
                  <li className="sub-item"><Link to="/vfd-list" className={"sub-link "+ (Pathloc==='vfd-list' ? " active" :"")}>VFD</Link></li>
                  <li className="sub-item"><Link to="/boe-list" className={"sub-link "+ (Pathloc==='boe-list' ? " active" :"")}>BOE</Link></li>
                  <li className="sub-item"><Link to="/library-list" className={"sub-link "+ (Pathloc==='library-list' ? " active" :"")}>Library</Link></li>
                  <li className="sub-item"><Link to="/colleges-list" className={"sub-link "+ (Pathloc==='colleges-list' ? " active" :"")}>Colleges</Link></li>
                  <li className="sub-item"><Link to="/appraisel-summary" className={"sub-link "+ (Pathloc==='appraisel-summary' ? " active" :"")}>Summary Report</Link></li>

                </ul>
            </li>
            <li className={"main-item "+(expanded3 ? " expand ":"")+ (Pathloc==='property-list'   ? " active" :"")}>
              <a className="menu-link"  onClick={() => setExpanded3(!expanded3)}> <i class="flaticon-381-archive"></i> Properties <span class="fa fa-chevron-down"></span></a>
              <ul className="sub-menu-item">
                  <li className="sub-item"><Link to="/property-list" className={"sub-link "+ (Pathloc==='property-list' ? " active" :"")}>Property Details</Link></li>
                </ul>
            </li>

          </ul>
			
			  </PerfectScrollbar>
      </div>
    );
};
  


export default SideBar;
