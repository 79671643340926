import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";

/// App

import LeaseDetails from './components/AppsMenu/AppProfile/LeaseDetails'
import SurplusDetails from './components/AppsMenu/AppProfile/surplusDetails'
import LeaseReport from './components/AppsMenu/AppProfile/LeaseReport'
import SurplusReport from './components/AppsMenu/AppProfile/SurplusReport'
import PropertyList from './components/AppsMenu/AppProfile/PropertyList'
import CountyOwnedList from './components/AppsMenu/AppProfile/CountyOwnedList'
import CountyVacantList from './components/AppsMenu/AppProfile/CountyVacantList'
import PoliceList from './components/AppsMenu/AppProfile/PoliceList'
import VFDList from './components/AppsMenu/AppProfile/VFDList'
import BOEList from './components/AppsMenu/AppProfile/BOEList'
import LibraryList from './components/AppsMenu/AppProfile/LibraryList'
import CollegesList from './components/AppsMenu/AppProfile/CollegesList'
import AppraiselSummary from './components/AppsMenu/AppProfile/AppraiselSummary'

/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import ForgotPassword from './pages/ForgotPassword'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "Lease-details", component: LeaseDetails},
    { url: "Lease-report", component: LeaseReport},
    { url: "surplus-details", component: SurplusDetails},    
    { url: "surplus-report", component: SurplusReport},
    { url: "property-list", component: PropertyList},
    { url: "county-owned-list", component: CountyOwnedList},
    { url: "county-vacant-list", component: CountyVacantList},
    { url: "police-list", component: PoliceList},
    { url: "vfd-list", component: VFDList},
    { url: "boe-list", component: BOEList},
    { url: "library-list", component: LibraryList},
    { url: "colleges-list", component: CollegesList},
    { url: "appraisel-summary", component: AppraiselSummary},

    

    /// pages
    { url: 'forgot-password', component: ForgotPassword },
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
  ]
 
  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
