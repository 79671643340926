
import React, { useLayoutEffect, useState } from 'react';
import DataGrid, {
    Column,
    Pager,
    Paging,
    SearchPanel,
    GroupPanel,
    Lookup,
    Export,
    ColumnFixing,
    Editing
    
} from 'devextreme-react/data-grid';
import axios from "axios";


function Percent({service}) {

    const [dataPList, setdataPList] = useState([]);
    useLayoutEffect(() => {
        console.log('Test');
        axios.get("https://larpapi.zovaya.net/api/percentlist/"+service,{
        
                headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
            }
        ).then(resp => {
            if (resp.status === 200) {
                setdataPList(resp.data)
            }
        })
    }, []);

    const onSaving = React.useCallback((e) => {
        var change = e.changes[0];
        var type   ='';
        console.log(change)
        if ("insertBeforeKey" in change) {
            console.log(change.insertBeforeKey)
            type = 'add';
        }

        if (change.type=="remove" && type !=='add' ) {
            e.promise = axios.put("https://larpapi.zovaya.net/api/percentdel", { id: change.key })
                .then(resp => {
                    axios.get("https://larpapi.zovaya.net/api/percentlist/"+service,
                    {
                        headers:{
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                        }
                    }).then(resp => {
                        if (resp.status === 200) {
                            setdataPList(resp.data);
                        }
                    })
                });
        }
        else if (type == 'add') {
            e.promise = axios.post("https://larpapi.zovaya.net/api/addpercent", { id: change.key,service:service, values: change.data })
                .then(resp => {
                    // no nothing medio
                    
                });
        }
        else if (change) {
            e.promise = axios.put("https://larpapi.zovaya.net/api/updatepercent", { id: change.key, values: change.data })
                .then(resp => {
                    // no nothing
                });
        }
    }, []);

    
  return (
            <DataGrid
                keyExpr="id"
                className={'dx-card wide-card per-card'}
                dataSource={dataPList}
                showRowLines={false}
                showBorders={true}
                columnAutoWidth={true}
                loadPanel={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                onSaving={onSaving}
                >
                    <Editing mode="form" onClick={()=> {console.log("Something clicked")}} allowAdding={true}  allowUpdating={true} allowDeleting={true} allowEditing={true} />
                    <Column dataField="year" caption="Year"  />
                    <Column dataField="value1" caption="Building Value"  />
                    <Column dataField="value2" caption="BPP Value"  />
                    

            </DataGrid>
  )
}

export default Percent