import React, { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import axios from "axios";
import 'devextreme/data/odata/store';
import "./map.css";
import { jsPDF } from 'jspdf';

import Swal from 'sweetalert2';


const AppraiselSummary = (props) => {
    const tableRef = useRef(null);
    
    const options = [];
    
    const currentyear =new Date().getFullYear()
    for (let year = 2020; year <= currentyear; year++) {
        options.push(year);
      }
    
    const [sumdata, setSumdata] = useState([]);
    const [countylist, setcountylist] = useState([]);    
    const [vacantlist, setvacantlist] = useState([]);
    const [policelist, setpolicelist] = useState([]);
    const [vfdlist, setvfdlist] = useState([]);
    const [boelist, setboelist] = useState([]);
    const [liblist, setliblist] = useState([]);
    const [collegelist, setcollegelist] = useState([]);

    useEffect(()=>{
        // props.loadData(2022,2021)
        
        axios.get("https://larpapi.zovaya.net/api/appraiselsummary", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setSumdata(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });

        axios.get("https://larpapi.zovaya.net/api/countyownedlist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setcountylist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        axios.get("https://larpapi.zovaya.net/api/countyvacantlist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setvacantlist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        axios.get("https://larpapi.zovaya.net/api/policelist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setpolicelist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        axios.get("https://larpapi.zovaya.net/api/vfdlist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setvfdlist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        axios.get("https://larpapi.zovaya.net/api/boelist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setboelist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        axios.get("https://larpapi.zovaya.net/api/librarylist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setliblist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        axios.get("https://larpapi.zovaya.net/api/collegelist", {
            
        headers:{
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
        }
        })
        .then((response) => {
            setcollegelist(response.data)
              
                
        })               
        .catch((error) => {
            console.log(error);
            
        });
        
        
        
    },[])

    const [reportname, setReport] = useState('')

    const [values1 ,setValues1]= useState('')
    const [values2 ,setValues2]= useState('')

    const [countytotal1 ,setcountytotal1] = useState(0)
    const [countytotal2 ,setcountytotal2] = useState(0)
    const [countydiff ,setcountydiff] = useState(0)
    const [countyper ,setcountyper] = useState(0)

    const [vacanttotal1 ,setvacanttotal1] = useState(0)
    const [vacanttotal2 ,setvacanttotal2] = useState(0)
    const [vacantdiff ,setvacantdiff] = useState(0)
    const [vacantper ,setvacantper] = useState(0)

    const [policetotal1 ,setpolicetotal1] = useState(0)
    const [policetotal2 ,setpolicetotal2] = useState(0)
    const [policediff ,setpolicediff] = useState(0)
    const [policeper ,setpoliceper] = useState(0)

    const [vfdtotal1 ,setvfdtotal1] = useState(0)
    const [vfdtotal2 ,setvfdtotal2] = useState(0)
    const [vfddiff ,setvfddiff] = useState(0)
    const [vfdper ,setvfdper] = useState(0)

    const [librarytotal1 ,setlibrarytotal1] = useState(0)
    const [librarytotal2 ,setlibrarytotal2] = useState(0)
    const [librarydiff ,setlibrarydiff] = useState(0)
    const [libraryper ,setlibraryper] = useState(0)
    
    const [collegetotal1 ,setcollegetotal1] = useState(0)
    const [collegetotal2 ,setcollegetotal2] = useState(0)
    const [collegediff ,setcollegediff] = useState(0)
    const [collegeper ,setcollegeper] = useState(0)
    
    const [boe_estotal1 ,setboe_estotal1] = useState(0)
    const [boe_estotal2 ,setboe_estotal2] = useState(0)
    const [boe_esdiff ,setboe_esdiff] = useState(0)
    const [boe_esper ,setboe_esper] = useState(0)
    
    const [boe_hstotal1 ,setboe_hstotal1] = useState(0)
    const [boe_hstotal2 ,setboe_hstotal2] = useState(0)
    const [boe_hsdiff ,setboe_hsdiff] = useState(0)
    const [boe_hsper ,setboe_hsper] = useState(0)

    const [boe_mstotal1 ,setboe_mstotal1] = useState(0)
    const [boe_mstotal2 ,setboe_mstotal2] = useState(0)
    const [boe_msdiff ,setboe_msdiff] = useState(0)
    const [boe_msper ,setboe_msper] = useState(0)

    const [boe_obtotal1 ,setboe_obtotal1] = useState(0)
    const [boe_obtotal2 ,setboe_obtotal2] = useState(0)
    const [boe_obdiff ,setboe_obdiff] = useState(0)
    const [boe_obper ,setboe_obper] = useState(0)

    const [alltotal1 ,setalltotal1] = useState(0)
    const [alltotal2 ,setalltotal2] = useState(0)
    const [alldiff ,setalldiff] = useState(0)
    const [allper ,setallper] = useState(0)

    let USDollar = new Intl.NumberFormat();
    
    const {onDownload}  = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Appraisel Summary '+reportname,
        sheet: 'Appraisel Summary '+reportname
    })
    const generatePDF = () => {

        const report = new jsPDF('landscape','px','a4');
        report.html(document.querySelector('#table-report')).then(() => {
            report.save('Appraisel Summary  '+reportname+'.pdf');
        });
    }
    const handleSubmit = (event)=> {
        event.preventDefault();
        const startdate = event.target.startdate.value;
        const enddate = event.target.enddate.value;
        if(startdate===enddate){
            Swal.fire(
                '',
                'Year must be different !',
                'warning'
                )
        }else if(startdate < enddate){
            Swal.fire(
                '',
                'Year Value 1 must be greater than Value 2 !',
                'warning'
                )
        }else{
            setReport(startdate+'-'+enddate)
            setValues1(startdate)
            setValues2(enddate)

            const vacantarray = getvalues(startdate,enddate,vacantlist,'county_vacant')
            setvacanttotal1(vacantarray.data1)    
            setvacanttotal2(vacantarray.data2) 
            setvacantdiff(vacantarray.data3)
            setvacantper(vacantarray.data4)
            const countyarray = getvalues(startdate,enddate,countylist,'county_own')
            setcountytotal1(countyarray.data1)    
            setcountytotal2(countyarray.data2) 
            setcountydiff(countyarray.data3)
            setcountyper(countyarray.data4)
            const policearray = getvalues(startdate,enddate,policelist,'police')
            setpolicetotal1(policearray.data1)    
            setpolicetotal2(policearray.data2) 
            setpolicediff(policearray.data3)
            setpoliceper(policearray.data4)
            
            const vfdarray = getvalues(startdate,enddate,vfdlist,'vfd')
            setvfdtotal1(vfdarray.data1)    
            setvfdtotal2(vfdarray.data2) 
            setvfddiff(vfdarray.data3)
            setvfdper(vfdarray.data4)

            
            const libraryarray = getvalues(startdate,enddate,liblist,'library')
            setlibrarytotal1(libraryarray.data1)    
            setlibrarytotal2(libraryarray.data2) 
            setlibrarydiff(libraryarray.data3)
            setlibraryper(libraryarray.data4)
            
            const collegearray = getvalues(startdate,enddate,collegelist,'college')
            setcollegetotal1(collegearray.data1)    
            setcollegetotal2(collegearray.data2) 
            setcollegediff(collegearray.data3)
            setcollegeper(collegearray.data4)
            
            let boe_eslist = boelist.filter(obj => obj.boetype === 'Elementary Schools');
            const boe_esarray = getvalues(startdate,enddate,boe_eslist,'boe')
            setboe_estotal1(boe_esarray.data1)    
            setboe_estotal2(boe_esarray.data2) 
            setboe_esdiff(boe_esarray.data3)
            setboe_esper(boe_esarray.data4)

            let boe_hslist = boelist.filter(obj => obj.boetype === 'High Schools');
            const boe_hsarray = getvalues(startdate,enddate,boe_hslist,'boe')
            setboe_hstotal1(boe_hsarray.data1)    
            setboe_hstotal2(boe_hsarray.data2) 
            setboe_hsdiff(boe_hsarray.data3)
            setboe_hsper(boe_hsarray.data4)

            let boe_mslist = boelist.filter(obj => obj.boetype === 'Middle Schools');
            const boe_msarray = getvalues(startdate,enddate,boe_mslist,'boe')
            setboe_mstotal1(boe_msarray.data1)    
            setboe_mstotal2(boe_msarray.data2) 
            setboe_msdiff(boe_msarray.data3)
            setboe_msper(boe_msarray.data4)

            let boe_oblist = boelist.filter(obj => obj.boetype === 'Other Buildings');
            const boe_obarray = getvalues(startdate,enddate,boe_oblist,'boe')
            setboe_obtotal1(boe_obarray.data1)    
            setboe_obtotal2(boe_obarray.data2) 
            setboe_obdiff(boe_obarray.data3)
            setboe_obper(boe_obarray.data4)           
            
            setalltotal1(countyarray.data1+vacantarray.data1+policearray.data1+vfdarray.data1+boe_esarray.data1+boe_hsarray.data1+boe_msarray.data1+boe_obarray.data1+libraryarray.data1+collegearray.data1)    
            setalltotal2(countyarray.data2+vacantarray.data2+policearray.data2+vfdarray.data2+boe_esarray.data2+boe_hsarray.data2+boe_msarray.data2+boe_obarray.data2+libraryarray.data2+collegearray.data2) 
            setalldiff(countyarray.data3+vacantarray.data3+policearray.data3+vfdarray.data3+boe_esarray.data3+boe_hsarray.data3+boe_msarray.data3+boe_obarray.data3+libraryarray.data3+collegearray.data3)
            setallper(countyarray.data4+vacantarray.data4+policearray.data4+vfdarray.data4+boe_esarray.data4+boe_hsarray.data4+boe_msarray.data4+boe_obarray.data4+libraryarray.data4+collegearray.data4)
                 
            }
    };

    const getvalues = (startdate,enddate,array,type) =>{

        let per_vacant = sumdata.filter(obj => obj.service === type); 
        let ap2_1=0;let ap2_2 =0;   
        // let bvalue =0; let bbvalue=0;     
        array.map(function(data){
            let Trend_b = data.trend_building_value
            let Trend_bb = data.trend_bpp_value
            let tbv=data.trend_building_value;
            let tbb=data.trend_bpp_value;
            per_vacant.map(function(obj){
                
                if(obj.year <= enddate){ 
                    Trend_b=Trend_b*obj.value1 
                    Trend_bb=Trend_bb*obj.value2 
                }
                if(obj.year <= startdate){                      
                    tbv=tbv*obj.value1 
                    tbb=tbb*obj.value2
                }                   

            })
            // bvalue=bvalue+Trend_b
            // bbvalue=bbvalue+Trend_bb
            if(type === 'library'){
                ap2_2+=Trend_b+Trend_bb+data.edp_value+data.misc_value+data.vehicle_value
                ap2_1+=tbv+tbb+data.edp_value+data.misc_value+data.vehicle_value 
            } else if(type === 'boe' || type === 'vfd'){
                ap2_2+=Trend_b+Trend_bb+data.edp_value+data.misc_value+data.vehicle_value+data.me_value
                ap2_1+=tbv+tbb+data.edp_value+data.misc_value+data.vehicle_value+data.me_value
            }
            else {
                ap2_2+=Trend_b+Trend_bb+data.edp_value+data.misc_value+data.me_value
                ap2_1+=tbv+tbb+data.edp_value+data.misc_value+data.me_value 
            }
            
                                        

        }) 
        
        // console.log([bvalue,bbvalue])
        let diff = ap2_1-ap2_2;
        let percentages = diff/ap2_2*100

        return {data1:ap2_1, data2:ap2_2, data3:diff, data4:percentages }

    }

    

    

    

    return(
        <div>
            <div>
        <React.Fragment>
            {/* <h2 className={'content-block'}>Appraisel Summary Report </h2> */}
            <form onSubmit={handleSubmit}>
                        <FormControl style={{ margin:'20px',width:'100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} style={{margin:'auto',justifyContent:'center'}}>
                                    
                                    <Grid item xs={12} md={2} >
                                        <FormControl variant="filled" style={{ minWidth:180,backgroundColor:'#fff'}}>                                        
                                            <InputLabel id="Year1"> Year Value 1</InputLabel>
                                            <Select    labelId="Year1" defaultValue={currentyear} id="startdate"   name="startdate" label="Year Value 1" >
                                                
                                                {options.map(function(listyear){

                                                    return  <MenuItem value={listyear}>{listyear}</MenuItem>

                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl variant="filled"  style={{ minWidth:180,backgroundColor:'#fff'}}>
                                            <InputLabel id="Year2"> Year Value 2</InputLabel>
                                            <Select    labelId="Year2" defaultValue={(currentyear-1)} id="enddate"   name="enddate" label="Year Value 2" >
                                                
                                                {options.map(function(listyear){

                                                     return  <MenuItem value={listyear}>{listyear}</MenuItem>

                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{display:'flex',alignItems:'center'}}>
                                         <button type="submit"   className=" btn-apl btn-apply">Apply</button>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{display:'flex',alignItems:'center'}}>
                                    <a className='btn btn-export' onClick={onDownload} title='Export Excel' >Export <i className='dx-icon dx-icon-xlsxfile dx-list-item-icon'></i></a>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
            </form>
            <div style={{width:'90%',borderRadius:'5px',margin:'0px auto 0',padding:'30px'}}>
                <Box sx={{ flexGrow: 1 }}>
                    <h4 className='text-center'>Summary {reportname}</h4>
                    <div style={{float:'right',marginBottom:'10px'}}>
                        {/* <a className='export-buttons' onClick={onDownload} title='Export Excel' > <i className='dx-icon dx-icon-xlsxfile dx-list-item-icon'></i></a> */}
                        {/* <a className='export-buttons' onClick={generatePDF} title='Export Pdf' > <i className='dx-icon dx-icon-pdffile dx-list-item-icon'></i></a> */}
                    </div>
                    <div className="table-responsive">
                        <table className="aprl table table-bordered table-striped" ref={tableRef} id='table-report'>
                            <thead>
                                
                               
                                <tr>
                                    <th className='text-left'>Description</th>
                                    <th className='text-center'>{values1} Values</th>
                                    <th className='text-center'>{values2} Values</th>
                                    <th className='text-center'> $ Change</th>
                                    <th className='text-right'> % Change</th>
                                </tr>


                            </thead>
                            <tbody>
                                <tr>
                                    <td>County Owned Facilities Total</td>
                                    <td className='text-right' > ${ USDollar.format( countytotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( countytotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( countydiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { countyper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>County Vacant Buildings Total</td>
                                    <td className='text-right' > ${ USDollar.format( vacanttotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( vacanttotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( vacantdiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { vacantper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Police Department/Courts/Sheriff & Criminal Justice Total</td>
                                    <td className='text-right' > ${ USDollar.format( policetotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( policetotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( policediff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { policeper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Prince George's Volunteer Fire Departments Total</td>
                                    <td className='text-right' > ${ USDollar.format( vfdtotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( vfdtotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( vfddiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { vfdper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Board of Education: Elementary Schools Total</td>
                                    <td className='text-right' > ${ USDollar.format( boe_estotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( boe_estotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( boe_esdiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { boe_esper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Board of Education: Middle Schools Total</td>
                                    <td className='text-right' > ${ USDollar.format( boe_mstotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( boe_mstotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( boe_msdiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { boe_msper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Board of Education:  High Schools Total</td>
                                    <td className='text-right' > ${ USDollar.format( boe_hstotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( boe_hstotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( boe_hsdiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { boe_hsper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Board of Education:  Other Buildings Total</td>
                                    <td className='text-right' > ${ USDollar.format( boe_obtotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( boe_obtotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( boe_obdiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { boe_obper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Prince George's County Library Total</td>
                                    <td className='text-right' > ${ USDollar.format( librarytotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( librarytotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( librarydiff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { libraryper.toFixed(2) || 0} %</td>
                                </tr>
                                <tr>
                                    <td>Prince George's County Community College Total</td>
                                    <td className='text-right' > ${ USDollar.format( collegetotal1.toFixed() || 0)}</td>
                                    <td className='text-right' > ${ USDollar.format( collegetotal2.toFixed() || 0)}</td>                                    
                                    <td className='text-right' > ${ USDollar.format( collegediff.toFixed()|| 0)}</td>                                                                     
                                    <td className='text-right' > { collegeper.toFixed(2) || 0} %</td>
                                </tr>
                            </tbody>
                            <tfoot>                                
                                <tr>
                                    <th className='text-green text-left' >Total</th>
                                    <th className='text-right'>${ USDollar.format(alltotal1.toFixed() || 0)}</th>
                                    <th className='text-right'>${ USDollar.format(alltotal2.toFixed() || 0)}</th>
                                    <th className='text-right'> ${ USDollar.format(alldiff.toFixed() || 0)}</th>
                                    <th className='text-right'>  {alldiff !== 0 ? ( (alldiff/alltotal2*100).toFixed(2) || 0) : '0.00' } %</th>
                                </tr>
                            </tfoot>

                        </table>

                    </div>
                </Box>
            </div>
            
            

            


        </React.Fragment>
            </div>
        </div>
    )
}

export default AppraiselSummary;