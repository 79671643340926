import axios from "axios";
import { LOAD_DASH_DATA, LOAD_MONTH_SURPLUS } from "../actions/dashactions";

const initialState =
{
    
    lease: {
        annualleasecost:0,
        monthlyleasecost:0,
        squarefeetoccupied:0,
        totalleases:0,
        totalsubleases:0,
        averagesquarefootcost:0,
        noofnewleases:0,
        noofterminatedleases:0,
        expleasesw6mos:0,
        percentageofleasedcountyspace:0,
        averagefexecleasesorstaff:null,
        noofleasereviewersornegotiators:null
    },

    surplus:{
        totalnoofproperties:0,
        noofpropertiessold:0,
        noofpendingcontracts:0,
        noofremainingpropertiestobesold:0,
        noofpropertiesonHold:0,
        revenuetodate:0,
        revenueexpectedfrompendingcontracts:0,
        percentsold:0,
        percentpending:0
    }

}


const HomeReducer= (state= initialState, actions) =>
{
    console.log("Home Reducer called\r\n\r\n")
    console.log(actions.type)
    var data = null; 
    if (actions.type === LOAD_DASH_DATA)
    {
                return {
                    ...state,
                    lease:actions.payload.lease,
                    surplus:actions.payload.surplus
                }
            }
    else if (actions.type === LOAD_MONTH_SURPLUS)
    {
        return {
            ...state,
            ...initialState,
            surplus:actions.payload.Monthsurplus
        }
    }
        return state;
}

export default HomeReducer;