import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Geocode from "react-geocode";

var location = {lat:null, lng: null}
class MapContainer2 extends Component {  
  
    componentDidMount(){
        // Geocode.setApiKey("AIzaSyBv_wvwO4gmpfrodo-MqErJ6IUhwLIlLaI")
        // Geocode.setLanguage("en");
        // Geocode.setLocationType("ROOFTOP");
        // console.log("geocode")
        // Geocode.fromAddress("175 corning Ln, Gaithersburg, MD 20878").then(
        //     (response) => {
        //         console.log(response)
        //         location = {lat:response.results[0].geometry.location.lat, lng:response.results[0].geometry.location.lat};})
    
    }
    
  render() {
    // {console.log("Location")
    // console.log(this.props)}
    return (

      
      <Map
      google={this.props.google}
      style={{height:"250px",width:"450px", position:"absolute"}}
      center={{
        lat: this.props.lat, 
            lng: this.props.lng
      }}
      zoom={14}
      onClick={this.onMapClicked}
      
    > 

    <Marker position={{lat: this.props.lat, 
            lng: this.props.lng}} title={this.props.name}/>
    
        <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>{"175 Corning Lane, Gaithersburg,MD 20878"}</h1>
            </div>
        </InfoWindow>
      </Map>
      
    );
  }
};
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBv_wvwO4gmpfrodo-MqErJ6IUhwLIlLaI'
})(MapContainer2)
