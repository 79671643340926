import axios from "axios";

export const LOAD_DASH_DATA = '[ACTION] lOAD DASHBOARD DATA';
export const LOAD_MONTH_SURPLUS = '[ACTION] lOAD MONTHLY SURPLUS DATA';

var lease = null; 
export function loadData(leasetype){
    return(dispatch, getState) =>{
        axios.defaults.headers['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers["Access-Control-Allow-Origin"] = '*'
        axios.defaults.headers["Access-Control-Allow-Methods"] = 'GET, POST, PUT, OPTIONS'
        axios.get(
            `https://larpapi.zovaya.net/api/leasesummary/${leasetype}`,
            {
                headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
            }
        ).then(resp =>{
            lease = resp.data
            console.log(resp.data)
            axios.get(`https://larpapi.zovaya.net/api/surplussummary`,{
                headers:{
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS'
                }
            }).then(sp=> {
                console.log(resp.data, sp.data) 
                const payload= {lease:resp.data, surplus: sp.data}   
            dispatch({type:LOAD_DASH_DATA,  payload})})
        }).catch(err => {});
        
    };
}



export function MonthData(month)
{
    console.log(month)
    return(dispatch, getState) =>{
    axios.get(`https://larpapi.zovaya.net/api/monthlysurplus/${month}`,{
                headers:{
                    'Access-Control-Allow-Origin': '*'
                }
            }).then(sp=> {
                console.log( sp.data) 
                const payload= {Monthsurplus: sp.data}   
            dispatch({type:LOAD_MONTH_SURPLUS,  payload})})
        }
}
