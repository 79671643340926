import React from 'react'

function Filezone() {
  return (
    <div>
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <g clip-path="url(#clip0_604_10212)">
                <path d="M50.6667 0H13.3333C9.79841 0.00423429 6.40949 1.41035 3.90992 3.90992C1.41035 6.40949 0.00423429 9.79841 0 13.3333L0 50.6667C0.00423429 54.2016 1.41035 57.5905 3.90992 60.0901C6.40949 62.5897 9.79841 63.9958 13.3333 64H50.6667C54.2016 63.9958 57.5905 62.5897 60.0901 60.0901C62.5897 57.5905 63.9958 54.2016 64 50.6667V13.3333C63.9958 9.79841 62.5897 6.40949 60.0901 3.90992C57.5905 1.41035 54.2016 0.00423429 50.6667 0V0ZM13.3333 5.33333H50.6667C52.7884 5.33333 54.8232 6.17619 56.3235 7.67648C57.8238 9.17677 58.6667 11.2116 58.6667 13.3333V50.6667C58.6621 51.8546 58.3886 53.0261 57.8667 54.0933L33.432 29.6587C32.1938 28.4202 30.7238 27.4377 29.1059 26.7675C27.488 26.0972 25.7539 25.7522 24.0027 25.7522C22.2514 25.7522 20.5173 26.0972 18.8994 26.7675C17.2815 27.4377 15.8115 28.4202 14.5733 29.6587L5.33333 38.896V13.3333C5.33333 11.2116 6.17619 9.17677 7.67648 7.67648C9.17677 6.17619 11.2116 5.33333 13.3333 5.33333ZM13.3333 58.6667C11.2116 58.6667 9.17677 57.8238 7.67648 56.3235C6.17619 54.8232 5.33333 52.7884 5.33333 50.6667V46.4373L18.3413 33.4293C19.0843 32.6859 19.9664 32.0962 20.9373 31.6938C21.9083 31.2915 22.949 31.0844 24 31.0844C25.051 31.0844 26.0917 31.2915 27.0627 31.6938C28.0336 32.0962 28.9157 32.6859 29.6587 33.4293L54.0933 57.8667C53.0261 58.3886 51.8546 58.6621 50.6667 58.6667H13.3333Z" fill="#2C3175"/>
                <path d="M42.6673 27.9991C44.5133 27.9991 46.3178 27.4517 47.8526 26.4261C49.3875 25.4006 50.5838 23.9429 51.2902 22.2374C51.9966 20.532 52.1814 18.6554 51.8213 16.8449C51.4612 15.0344 50.5723 13.3714 49.267 12.0661C47.9617 10.7608 46.2986 9.87187 44.4882 9.51174C42.6777 9.15161 40.8011 9.33644 39.0956 10.0429C37.3902 10.7493 35.9325 11.9456 34.9069 13.4804C33.8814 15.0153 33.334 16.8198 33.334 18.6657C33.334 21.1411 34.3173 23.5151 36.0677 25.2654C37.818 27.0157 40.192 27.9991 42.6673 27.9991ZM42.6673 14.6657C43.4584 14.6657 44.2318 14.9003 44.8896 15.3399C45.5474 15.7794 46.0601 16.4041 46.3628 17.135C46.6656 17.8659 46.7448 18.6702 46.5905 19.4461C46.4361 20.222 46.0552 20.9348 45.4957 21.4942C44.9363 22.0536 44.2236 22.4345 43.4477 22.5889C42.6718 22.7432 41.8675 22.664 41.1366 22.3613C40.4057 22.0585 39.781 21.5458 39.3414 20.888C38.9019 20.2302 38.6673 19.4569 38.6673 18.6657C38.6673 17.6049 39.0887 16.5875 39.8389 15.8373C40.589 15.0872 41.6065 14.6657 42.6673 14.6657Z" fill="#2C3175"/>
            </g>
            <defs>
                <clipPath id="clip0_604_10212">
                <rect width="64" height="64" fill="white"/>
                </clipPath>
            </defs>
            </svg>

        </div>
        <div className='file-inner-text mt-3' ><span className='file-click-text'>Click To Upload</span> Or Drag And Drop</div>
        
        

    </div>
  )
}

export default Filezone

export function Filezonefile(){
    return (
        <div className='file-inner-text'><span className='file-click-text'>Click To Upload Files</span> Or Drag And Drop</div>
    )

}
